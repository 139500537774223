#loading-progress {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    // background: #fff;
    // transform: translateY(0%) !important;
    // display: none;
  
    &::before,
    &::after {
      transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
    }
  
    &::before,
    &::after {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
      // transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
    }
  
    &::before {
      background: #219fff;
      // opacity: 0.9;
      // display: none;
    }
  
    &::after {
      background: #fff;
    }
  
    &.hide-it {
      visibility: hidden;
      transition-delay: 1.5s; // last layer delay "::before" + duration = 1.1 + 0.4
  
      &::before,
      &::after {
        transform: translateY(-100%);
      }
  
      &::before {
        transition-delay: 1.1s;
        // transition-duration: 0.4s;
      }
    }
  
    .logo {
		position: relative;
		z-index: 1;
		margin-bottom: 20px;
		transition: all 0.4s 0.3s;
    }
  
    .lp-content {
      position: relative;
      width: 100%;
      z-index: 1;
      // opacity: 1 !important;
      transition: all 0.4s;
    }
  
    .lp-counter {
        margin-bottom: 9px;
        font-size: 14px;
        font-weight: 300;
        font-family: "Titillium Web", sans-serif;
        color: #888;
  
      #lp-counter {
        display: inline-block;
        width: 33px;
        font-weight: 600;
        text-align: left;
        color: #222;
      }    
    }
  
    .lp-bar {
      position: relative;
      margin: auto;
      width: 150px;
      background: #eee;
  
      #lp-bar {
        height: 2px;
        background: #219fff;
        width: 0;
      }    
    }
  }