@import "../../scss/style.scss";

@import "../../includes/loader.scss";
@import "../../includes/header.scss";
@import "../../includes/footer.scss";
@import "../../includes/countdown.scss";
@import "../../includes/speaker.scss";

body {
    background-color: #fff;
}
h2 {
    font-size:30px;
    letter-spacing:-0.4px;
}
h3 {
    font-size:24px;
    span {
        color:#7b7b7b;
        font-size: 18px;
        font-weight: 300;
    }
}
h4 {
    font-size:18px;
}
h2,
h3,
h4 {
    padding:10px 0;
    color:#161616;
    line-height: 1.3;
    font-family: "Titillium Web", 'Nunito Sans', sans-serif;
    
}
p {
    color:#545454;
    line-height: 1.5;
    font-family: 'Titillium Web';
    font-size: 18px;
    font-weight: 300;
}
.only-mobile {
    display: block;
    @include media-md {
        display: none;
    }
}
.webinar-data {
    display: flex;
    padding:10px 0 0 0;
    span {
        min-width: 40%;
        max-width: 40%;
        color:#7b7b7b;
    }
    b{
        min-width: 40%;
        max-width: 40%;
        color:#161616;
    }
}
.video-form {

	border: 1px solid #00000021;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 1px 1px 2px #0000001c;
    .button-set {
        margin-bottom:0 !important;
        width: 100%;
        button {
            width: 100%;
        }
    }
}
.circle-icon {
    background-color: #EEF2F6;
    border-radius: 50%; 
    height: 80px;
    width: 80px;
    margin:0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border:2px solid #fff;
}
.time-line {
    .time-line-item {
        display: flex;
        align-items: flex-start;
        position: relative;
        margin:5px 0;
        padding-bottom:30px;

        &:not(:last-child){
            &:before {
                content: "";
                width: 2px;
                border: 1px solid #ccc;
                top: 0;
                bottom: 0px;
                position: absolute;
                left: 57px;
                top: 55px;

            }
        }

        
        span {
            background-color: #EEF2F6;
            padding:5px;
            border-radius: 10px;
            margin:5px 5px 5px 5px;
            min-width: 105px;
            text-align: center;
            font-weight: 600;
            color:#161616;
            border:1px solid #00000021;
        }
        > div {
            margin-left:20px;
            padding-top:7px;
            min-height: 65px;
            h5 {
                text-align: left;
                display: none;
            }
            p {
                font-size: 18px;
                margin: 0;
                padding: 0;
                margin-right: 10px;
                margin-bottom: 10px;
                text-align: left;
                max-width: 310px;
            }
        }
    }
}

.btn-row {
	display: flex;
	flex-direction: column;
	width: fit-content;
	justify-content: center;
	align-content: center;

	p.txt {
		color: white;
		margin-top: 10px;
    	font-weight: 300;
    	opacity: 0.65;    	
		font-size: 15px;
	}

	&.on-white {
		p.txt {
			color: #545454;
			opacity: 1;
		}
	}
}

.box-form {
	padding: 30px 20px;

	@media (min-width: 565px) {
		padding: 30px 50px;
	}

	.box-title {
		padding-bottom: 20px;
    }
    .accept-legal{
        display: block;
        text-align: left;
        font-size:13px;
        color:#545454;
        padding:5px 10px;
        input[type="radio"], input[type="checkbox"]{
            display:inline;
            width:16px;
            height:16px;
            border-radius:3px;
            border:1px solid #ccc;
            vertical-align: bottom;
            margin-bottom: 2px;
            margin-right: 3px;
        }
        label {
            font-size:13px;
            color:#545454;
            display:inline;
            vertical-align: bottom;
        }
        
        a {
            font-size:13px;
            color:var(--input-blue-label);
            display:inline;
            vertical-align: bottom;
        }
        .error {
            visibility: hidden;
        }
    }
}
.slide-content { 
	p {
		font-size: 16px;
	}
	.subheading	{
		margin: 12px 0;
	}
}

.subscribe-form {
    h3, 
    .button-set {
		width: 100%;
		margin-top: 25px;
    }
    .ctv-button {
		margin-left:auto;		
	}
	
	label {
		margin: 0;
	}

	.ctv-input-field {
		margin-bottom: 0;

		input[type] { 
			height: 32px;
		}

		.error {
			margin: 0;
		}
	}
}

ctv-dialog-subscribe-form {
    div.ctv-dialog-shadow.modal div.ctv-dialog-wrapper {
       
        max-height: none;
        min-height: auto;

        div.ctv-dialog-header {
            @include media-sm {
                display: none;
            }
        }

        @include media-sm {
            max-height: calc(100vh - 45px);
            height: auto;
        }

        div.ctv-dialog-body {
	
            @include media-sm {
                height: auto;
                max-height: calc(100vh - 150px);
                border-radius: 5px;
                overflow: hidden;
            }
            
            ctv-channel-info-dialog-step2 {
                max-width: 588px;
                margin: 0 auto;
                display: block;
            }

        }
        .ctv-dialog-footer {
            display: none !important;
        }
    }
}

.text-white {

    .countdown .countdown-item {
        > div {
            strong {
                color:white;
            }
            span {
                color:#ccc;
            }
        }
    }

    .webinar-data {
        padding-left:10px;
        b {
            color:#fff;
        }
        span {
            color:#ccc;
        }
    } 
} 

/* START theme overwrites */
@media (max-width: 550px) {
    .section-parallax > .bg-element {
        background-position-x: center !important;
    }
}
#header-bar-1 .hb-content {
    display: flex;
    justify-content: center;
    @include media-sm {   
        justify-content: flex-start;
    }
}

.banner-center-box {
    padding: 100px 0 100px;
    
    
    .btn-row.mt-20 {
        justify-content: center;
        width: auto;
        @include media-sm {   
            justify-content: flex-start;
            width: fit-content;
        }
    }
    @include media-sm {   
        text-align: left;
    }
}
.box-center {
    text-align: center;
    
    .btn-row.mt-20 {
        justify-content: center;
        width: auto;
        @include media-sm {   
            justify-content: flex-start;
        }
    }
    @include media-sm {   
        text-align: left;
    }
}

form .form-group {
    .field-icon {
        left: 8px;
        top: 5px;
    }
}
input[type="submit"]:hover, 
input[type="submit"].form-control:hover, 
button[type="submit"]:hover, 
button[type="submit"].form-control:hover {
    background-color: #219fff;
    opacity: 0.85;
}
.btn.dark, 
.btn.hover-dark:hover {
    background-color: #219fff;
    border-color: #219fff;
    opacity: 0.85;
}
.logo-header{
    margin-left:0;
}

.box-service-1 {
    pointer-events: none;
    .box-icon {
        background-color: white;
        //border:2px solid var(--bg-color-orange);
        border:0;
        width: 195px;
        height: 195px;
        padding:0;
        margin-bottom:5px;

        img {
            max-width: 195px;
            margin-top:0;
        }
    }
    .box-content {
        max-width: 242px;
    }
    h4 {
        font-size:24px;
        margin-top:0;
        padding-top:0;
        line-height:1.2;
    }
    p {
        font-size:16px;
    }
} 

.style-2.btn-row small {
    font-family: 'Titillium Web';
    color:#545454;
    opacity: 1;
    font-size:15px;
}
#banner{
    h2.subheading {
		font-size: 23px;
		text-transform: none;
		letter-spacing: 1px;
        line-height: 1.5; 
        font-weight: 300;
    }
    .banner-center-box {
        padding: 120px 0 60px;
        @include media-md {    
            padding: 180px 0 180px;
        }
    }
    @media (max-width: 768px) {
        .banner-parallax [class*="col-"]:not(:first-child) .banner-center-box {
            padding-top: 0 !important;
            margin-top: -50px;
        }
    }
    .color-bg-dark {
        background-color: #006BD6;
        background-image: repeating-linear-gradient(180deg, #06397a, transparent );
        opacity: 0.55;
    }
}
.hydrokit-section {
    //background-color: #f8f8f8;
    background-position: center;
    background-size: cover;
    //background: rgb(33,159,255);
    //background: linear-gradient(180deg, rgba(33,159,255,1) 0%, rgba(140,204,255,1) 100%);
    background-image: url('../../images/bg-parallax/path-bg.jpg');
    border-top:1px solid rgb(228, 228, 228);
    border-bottom:1px solid rgb(228, 228, 228);
    .banner-center-box {
        background: rgba(255,255,255,0.75);
        box-shadow: 0 0 15px rgba(0,0,0,0.22);
        padding:25px 50px;
        margin: 15px 0;
        @include media-md {
            margin: 35px 0;
        }
        @include media-lg {
            margin: 50px 0;
        }
        img {
            @include media-md {
                margin-right:20px;
            }
        }
        h4 {
            font-size:33px;
            color:#2E3092;
            @include media-sm {
                text-align:left;
                width:100%;
            }
        }
        h5 {
            font-size:20px;
            color:#0DA959;
            margin-bottom:20px;
        }
        p {
            margin-bottom: 20px;
        }
        a {
            margin-top:10px;
            font-size: 18px;
            margin-bottom: 30px;
        }

    }
    
}
.banner-center-box {
    .view-product {
        text-shadow:0 1px 3px rgb(0 0 0 / 30%);
        color:#fff; 
        font-size:25px;
        span {
            display: block;
            margin:5px auto;
        }
    }
    .product-img {
        border-radius:15px; 
        box-shadow:0 2px 5px rgba(0,0,0,0.3);
        margin-bottom:30px;
        //max-width: 300px;
        transition: all 0.3s;
        @include media-md {    
            margin-bottom: 5px;
        }
    }
    /*
    &:hover {
        .product-img {
            box-shadow:0 0 1px rgba(0,0,0,0.3), 0 2px 10px rgba(255,255,255,0.35);
        }
    }
    */
}

.bk-gray-section {
    border:0;
    border-top:1px solid #fe7d022b;
    border-bottom:1px solid #fe7d022b;
}
.banner-video {
    height: 440px !important;
    img{
        object-fit: cover;
        height: auto !important;    
    }
    /*
    ctv-video {
        height: 440px !important;
        img,
        ctv-img {
            object-fit: cover;
            height: auto !important;    
        }
    }*/
    .icon-player-anim {
        position: absolute;
        width: 100px;
        height: 100px;
        background-color: var(--bg-color-orange);
        border-radius: 50%;
        left: 50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            transform: scale(2);
            color:white;
        }
    }
}
#section-cta-1,
#section-cta-1a,
.section-flat {
    .section-content {
        padding:50px 0 50px 0;
        text-align: center;
        @include media-md {    
            padding:80px 0 80px;
        }
    }
}

#section-customers-testimonials-1{    
    .section-content {
        padding:50px 0 50px
    }
}
.testimonial-row{
    @include media-md {
        display: flex; 
        align-items: stretch;
    }
} 
.testimonial-single-1 {
    @include media-md {
        display: flex;
        height: 100%;
    }
    .icon-quote {
        align-items: center;
        top:-20px;
    }
    .ts-content {
       padding:15px;
       @include media-md {
           padding:25px;
       }
   }
}
#section-clients-1 {
    padding:0;
    .section-content {
        padding:10px 0 10px 0;

        @include media-md {
            padding:30px 0 30px 0;
        }
    }
    .slider-clients .slick-slider .slick-slide{
        min-width: 150px;
        margin: 0 20px;
        @include media-md {
            min-width: 290px;
        }
    }
    .client-single img {
        //border-radius:5px;
        min-width: 150px;
        @include media-md {
            min-width: 290px;
        }
    }
}
// last section
#section-cta-1b {
    .section-content {
        padding:50px 0 50px 0;
        text-align: center;
        @include media-md {    
            padding:100px 0 100px;
        }
        }
}
#banner,
#section-cta-1a,
#section-cta-1b {
    h1,
    h2,
    .description {
        color:white;
        text-shadow: 0 1px 3px rgba(0,0,0,0.3);
    }
}

/* END theme overwrites */