header {
    display: block;
    position: relative;
    background: #fff;
    .logo-header {
        margin-top: 10px;
        width: 192px;
        height: 42px;
        
        @include media-md {    
            margin-top:25px;
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 140px;
        background: #020024;
        background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(0, 0, 0, 0.5) 100%);
        z-index: 1;
        @include media-md {    
            background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(0, 0, 0, 0.3) 100%);
            height: 70px;
        }
    }
}