.countdown {
    .countdown-item{
        display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		@include media-md {    
			justify-content: flex-start;
		}

        > div {
            flex: 0 0 24%;
            max-width: 120px;
            text-align: center;
            strong {
                border:1px solid #ccc;
                border-radius: 5px;
                font-size:28px;
                display: block;
                margin:5px;
                padding:8px 10px;
            }
            span {
                font-size:14px;
                color:#7b7b7b;
            }
        }
	}
	
	.countdown-date {
		margin-top: 10px;

		p {
			text-align: center;
			color: #ccc;
			font-size: 16px;
			max-width: 475px;

			span {
				color: white;
			}
		}
	}

	&.on-white {
		
		p {
			color: #555;
			span {
				color: black;
			}
		}
	}
}