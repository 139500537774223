@import "~@camtv/front-base-css/pure.scss";
@import "~@camtv/front-base-css/modifiers.scss";
@import "~@camtv/front-base-css/main.scss";

@import "./fonts.scss";

.ctv-button, .btn {
	position: relative;
	text-shadow: 1px 1px 1px rgb(0 0 0 / 10%);

	&.loading {
		> * {
			visibility: hidden;
		}
	
		&::after {
			content: "";
			position: absolute;
			width: 16px;
			height: 16px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			border: 4px solid transparent;
			border-top-color: #ffffff;
			border-right-color: #FFF;
			border-radius: 50%;
			animation: button-loading-spinner 1s ease infinite;
		}
	}
	
	@keyframes button-loading-spinner {
		from {
			transform: rotate(0turn);
		}
	
		to {
			transform: rotate(1turn);
		}
	}		
}

.ctv-input-field {
	margin-bottom: 0px;

	&.error {
		input[type=text], input[type=email] {
			box-shadow: 0 0 0 1px var(--input-color-error);
		}

		.error {
			margin-top: 4px;
			margin-bottom: 0;
		}		
	}
}

.bk-gray-section {
	background-color: #f8f8f8; 
	border: 1px solid #fe7d022b;
}
