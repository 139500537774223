.speaker-item {
    display:flex;
    margin-right: 10px;
    flex-direction: column;
    @include media-sm {    
        flex-direction: row;
    }
    > div {
        margin:5px 10px 5px 5px;
        &:first-child {
            text-align: center;
            min-width: 120px;
            img {
                margin-top:20px;
                border-radius: 50%;
                max-width: 200px;
                border:1px solid #0000002b;
                @include media-sm {    
                    margin-top:0;
                    max-width: -webkit-fill-available;
                    max-width: -moz-fill-available;
                    max-width: fill-available;
                    border-radius: 5px;
                }

            }
            .social {
                display: flex;
                align-items: center;
                justify-content: space-between;
                svg {
                    transform: scale(0.8);
                    color:#7b7b7b;
                }
            }
        }

        h3 {
            margin:0;
            padding:5px 0;
            font-size:20px;
            color:#161616;
        }

        span {
            color:#7b7b7b;
            font-size:14px;
            margin-bottom: 10px;
            display: flex;
        }

    }

}