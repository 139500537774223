/* footer */
.footer-wrap {
    margin-top: 0;
    @include media-md {
        margin-top: 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    .prefooter {
        background:#f8f8f8;
        padding:50px;
        border-bottom: 1px solid #ddd;

        @media screen and (max-width:350px) {
            padding:50px 20px;
        }

        @include media-md {
            padding:50px;
        }
        > div {
            max-width: 1260px;
            margin: 0 auto;
        }
        a {
            //border:1px solid var(--bg-color-orange);
            //border:1px solid #0092DE;
            border:1px solid #4daf50;
            border-radius:30px;
            display:flex;
            padding:10px;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;
            min-width: 230px;
            margin-bottom: 10px;
            background-color:#4daf50;
            //color:#0092DE;
            color:#fff;
            font-family: "Titillium Web", sans-serif;

            margin-bottom: 10px;

            @include media-lg {
                margin-bottom: 0px;
            }
            &.email-contact {
                margin-bottom: 0px;
            }
            svg {
                margin-right: 15px;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
    footer {
        max-width: 1310px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        
        
        @include media-md {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
        }
        
        @include media-lg {
            padding-left: 30px;
            padding-right: 30px;
        }
        
        .camtv-pages {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 15px;
            padding-bottom: 8px;
            border-bottom: 1px solid #ddd;
            
          
            
            @include media-md {
                padding: 0;
                border-bottom: none;
            }
            
            li {
                display: flex;
                flex-shrink: 1;
                font-size: 13px;
                color: #9f9f9f;
                
                &.dot {
                    font-size: 9px;
                    padding: 7px;
                    margin-left: 10px;
                }
                
                a {
                    padding: 7px;
                    color:var(--color-text-medium);
                    &.certificate {
                        display:flex; 
                        align-items:center;
                    }
                }
            }
        }

        .copy-socials-langs-wrap {
            display: flex;
            padding: 15px;
            
            @include media-md {
                padding: 0;
            }
            
            .copyright-area {
                font-size: 14px;
                color: #7E7E7E;
                margin-right: auto;

                @include media-md {
                    display: none;
                }
            }
            
            .ctv-social-area {
                display: flex;
                align-items: center;
                margin: 0 auto;
                @include media-lg {
                    justify-content: flex-end;
                }

                span {
                    display: none;

                    @include media-lg {
                        display: inline-flex;
                        font-size: 14px;
                        color: #7E7E7E;
                        margin-right: 20px;
                    }
                }

                a {
                    display: inline-flex;
                    margin-right: 20px;

                    &:last-child {
                        @include media-lg {
                            margin-right: 55px;
                        }
                    }
                }
            }

            .lang-area {
                position: relative;

                .flag {
                    width: 22px;
                    height: 14.77px;
                }

                .lang-switch {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    
                    span {
                        display: none;
                        font-size: 14px;
                        color: #7E7E7E;
                        margin-left: 8px;

                        @include media-sm {
                            display: initial;
                        }
                    }

                    .chevron {
                        width: 8px;
                        margin-left: 10px;
                    }
                }
        
                .lang-popup {
                    display: none;
                    position: absolute;
                    bottom: 30px;
                    right: -8px;
                    width: 120px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    padding: 15px 10px;
                    border-radius: 3px;

                    @include media-lg {
                        right: -28px;
                        width: 140px;
                    }
        
                    li {
                        &:not(:first-child) {
                            margin-top: 11px;
                        }
                        
                        button {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            padding: 5px;
                            
                            span {
                                font-size: 14px;
                                color: #7E7E7E;
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
ctv-legal-dialog{
    div.ctv-dialog-shadow.modal div.ctv-dialog-wrapper div.ctv-dialog-header .title {
        background-color: #219fff;
    }
    .ctv-dialog-body{
        li {
            width: auto;
        }
    } 
    .ctv-dialog-footer {
        ctv-button button {
            border-color: #219fff;
            background-color: #219fff;
        }
    }
} 
/* footer */